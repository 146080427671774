/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import {
  faAnglesDown,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { Accordion, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { GFLCharacter } from '../../modules/exilium/components/exilium-character';
import { ExiliumCharacterRatings } from '../../modules/exilium/data/exilium-ratings';

const ExiliumTierPage: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    ExiliumCharacterRatings
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Bulwark',
            tooltip: 'Bulwark',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_bulwark.png"
                width={24}
                alt="Bulwark"
              />
            )
          },
          {
            value: 'Sentinel',
            tooltip: 'Sentinel',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_sentinel.png"
                width={24}
                alt="Sentinel"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Vanguard',
            tooltip: 'Vanguard',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_vanguard.png"
                width={24}
                alt="Vanguard"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            label: 'AR',
            tooltip: 'Assault Rifle',
            value: 'AR'
          },
          {
            label: 'HG',
            tooltip: 'Handgun',
            value: 'HG'
          },
          {
            label: 'MG',
            tooltip: 'MG',
            value: 'MG'
          },
          {
            label: 'SG',
            tooltip: 'Shotgun',
            value: 'SG'
          },
          {
            label: 'SMG',
            tooltip: 'SMG',
            value: 'SMG'
          },
          {
            label: 'RF',
            tooltip: 'Rifle',
            value: 'RF'
          },
          {
            label: 'BLD',
            tooltip: 'Blade',
            value: 'BLD'
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Burn',
            tooltip: 'Burn',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_burn.png"
                width={24}
                alt="Burn"
              />
            )
          },
          {
            value: 'Corrosion',
            tooltip: 'Corrosion',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_corrosion.png"
                width={24}
                alt="Corrosion"
              />
            )
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_electric.png"
                width={24}
                alt="Electric"
              />
            )
          },
          {
            value: 'Freeze',
            tooltip: 'Freeze',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_freeze.png"
                width={24}
                alt="Freeze"
              />
            )
          },
          {
            value: 'Hydro',
            tooltip: 'Hydro',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_hydro.png"
                width={24}
                alt="Hydro"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = ExiliumCharacterRatings;

    if (sortOption === 'cn') {
      setSortOption('cn');
    }

    if (sortOption === 'cn_boss') {
      setSortOption('cn_boss');
    }

    if (sortOption === 'global') {
      setSortOption('global');
    }

    if (sortOption === 'global_boss') {
      setSortOption('global_boss');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.char
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'id');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption]);

  const sortingOptions = [
    { value: 'global', label: 'Global - Generic' },
    { value: 'global_boss', label: 'Global - Bossing' },
    { value: 'cn', label: 'CN - Generic' },
    { value: 'cn_boss', label: 'CN - Bossing' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page gfl-tier'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_tier.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium (GFL 2) Tier List</h1>
          <h2>
            Prydwen's tier list for Girls' Frontline 2: Exilium that rates all
            currently available characters.
          </h2>
          <p>
            Last updated: <strong>17/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Tier List Disclaimers</Accordion.Header>
          <Accordion.Body>
            <h6>Introduction</h6>
            <p>
              With GFL2’s hectic launch month finally behind us, we have taken
              the time to thoroughly revamp our tier list. This overhaul has
              given us the opportunity to address many foundational issues the
              tier list had and rework everything from the ground up.
            </p>
            <p>The intention behind this overhaul is to:</p>
            <ul>
              <li>Set clear boundaries between the various tier lists.</li>
              <li>
                Update the definitions and criteria to make them more in line
                with how the game is played.
              </li>
              <li>Adjust ratings across the board to reflect the changes.</li>
            </ul>
            <h6>Disclaimers</h6>
            <p>
              Tier lists are inherently a problematic way to convey information
              and should be used carefully. Here's some limitations we have
              identified:
            </p>
            <ul>
              <li>
                <strong>
                  Character tier lists do not convey synergies well
                </strong>
                : GFL2 is a heavily team-oriented game with a strong focus on
                character synergy. A tier list organized at the individual
                character level will inevitably run into presentation issues
                inherent to depicting an individual character’s value within a
                team without the context of the team(s). Readers are encouraged
                to exercise their due diligence when using the tier lists.
              </li>
              <li>
                <strong>Information gets lost in aggregation</strong>:
                Representing a character’s power level as an aggregate of their
                performance across different game modes introduces some level of
                ambiguity. The subjective nature of such assessments will
                inevitably cause a gap between a character’s rating and their
                actual in-game performance.
              </li>
              <li>
                <strong>The Tier lists are not an end-all reference</strong>:
                The Prydwen GFL2 Tier lists are intended to be used as an aid to
                quickly compare characters, especially for newer players trying
                to scope out the game’s roster. Players are heavily encouraged
                to supplement the Prydwen GFL2 Tier lists with the various
                resources available through the game’s many online communities,
                such as those available on the Official Darkwinter Discord.
              </li>
              <li>
                <strong>You may not even need the tier list</strong>: The
                reality is, GFL2 is not a particularly challenging game. This
                stems from the developers' design philosophy, which prioritizes
                accessibility to ensure that all players can experience the
                game’s content regardless of their investment or engagement
                level. Consequently, the difficulty is so low that referencing
                tier lists isn’t actually required to finish the vast majority
                of content—you may even enjoy the game more without them
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Tier List Coverage & Categories</Accordion.Header>
          <Accordion.Body>
            <h6>Coverage</h6>
            <p>
              Here's some additional information about the General & Bossing
              tier list split:
            </p>
            <ul>
              <li>
                <strong className="highlight">General</strong> tier list rates
                the character performance in the following content:
              </li>
              <ul>
                <li>Campaign Stages (Normal/Hard),</li>
                <li>Event Stages (Normal/Hard),</li>
                <li>Boss Fight (Standard Battle/Crossroad Challenge),</li>
                <li>Peak Value Assessment (at level),</li>
                <li>Expansion Drills (to Drill 10),</li>
                <li>All other trivial content.</li>
              </ul>
              <li>
                <strong className="highlight">Bossing</strong> tier list rates
                the character performance in the following content:
              </li>
              <ul>
                <li>Gunsmoke Frontline (Competitive)</li>
                <li>Expansion Drills (Drill 11+)</li>
              </ul>
            </ul>
            <h6>Categories</h6>
            <p>
              To help identify and distinguish between otherwise unrelated
              characters grouped in the same tiers, the tier list has been
              further arranged into columns to loosely categorize characters
              along the general lines of team building. These columns sort the
              characters into:
            </p>
            <ul>
              <li>
                <strong className="dps">Core</strong> - Core damage dealers are
                characters that serve as the centerpiece to their team’s
                offensive strategy. Their primary role is to output damage, and
                their teams are built around them.
              </li>
              <li>
                <strong className="amplifier">Core Support</strong> - Core
                Support characters enhance the performance of the Core by
                providing specific buffs, debuffs, or resources tailored to
                amplify their strengths. Examples include Springfield for TLL,
                Vector for Modkey QJ, and Mechty for Peritya/Klukai - each Core
                Support is designed to complement their respective Core(s).
              </li>
              <li>
                <strong className="healer">Utility</strong> - Utility characters
                provide niche abilities that add flexibility or fill gaps in the
                team composition. They specialize in areas such as mobility,
                cleansing buffs off enemies, or crowd control. While they may
                not directly boost damage, they ensure the effectiveness of the
                Core and Core Support by addressing specific challenges or
                situational needs.
              </li>
            </ul>
            <p>
              The intention behind the columns (beyond decluttering the tier
              list) is to provide newer players better context regarding a
              character's role in the team. While this arrangement helps
              organize the tier list, it is not without its trade-offs. Players
              are encouraged to consult more detailed community resources to
              understand characters' exact roles within their teams; characters
              often occupy multiple roles, and concessions inevitably must be
              made to fit them into a rigid column structure.
            </p>
            <h6>Special Tag</h6>
            <p>
              Characters with the{' '}
              <FontAwesomeIcon icon={faLink} width="18" className="red" /> tag
              in the top left corner{' '}
              <strong>
                require specific teammates to perform at their full potential
              </strong>
              . If you can't give them who they want, they will perform below
              the rating given to them. We will soon provide a list of all very
              important synergies/pairings.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Tier Rating Philosophy</Accordion.Header>
          <Accordion.Body>
            <h6>Criteria</h6>
            <p>Characters are evaluated as such:</p>
            <ul>
              <li>
                For both tier list, <strong className="rarity-SSR">SSR</strong>{' '}
                characters are rated at V0 (no dupes) and{' '}
                <strong className="rarity-SR">SR</strong> characters are rated
                at V6 (max dupes),
              </li>
              <ul>
                <li>
                  Some characters in the CN Bossing list have dupes attached to
                  them, but only the V1/V2 in cases where it impacts their
                  performance in a big way. We will soon add ratings that show
                  their V0 performance too.
                </li>
              </ul>
              <li>
                For the CN tier list, Mod Keys and their impact on the character
                is already included into the ratings,
              </li>
              <li>
                What's taken into consideration: raw power, versatility (teams
                and builds), utility (how unique or essential their kits are),
                how hard the characters are to play to their full potential,
              </li>
              <li>
                Characters are compared only within the same category when it
                comes to their placement on the tier list. So don't try to
                compare Core with Utility based on their ratings.
              </li>
            </ul>
            <h6>Ratings definitions</h6>
            <ul>
              <li>
                <strong>T0</strong> - Characters who almost always represent the
                strongest option within their teams.
              </li>
              <ul>
                <li>
                  These characters are highly versatile and can be seamlessly
                  integrated into various team compositions. They consistently
                  outperform their alternatives within their respective roles.
                </li>
                <li>
                  These characters significantly impact their teams’ performance
                  by contributing an incredible amount of value - either as
                  potent damage dealers or through powerful utility built into
                  their kits.
                </li>
              </ul>
              <li>
                <strong>T0.5</strong> - Characters not strong enough to be T0,
                but notably stronger than the average T1.
              </li>
              <ul>
                <li>
                  While we tried to be as explicit as possible in our rating
                  methodology, there will inevitably be characters that toe the
                  line regardless how we go about it. In the interest of
                  capturing this, this half-tier exists as an in-between.
                </li>
                <li>
                  Certain characters excel in their niche, but may have a
                  significant amount of their “value” tied to enabling some
                  other (T0) character. These characters are undeniably
                  important, especially given that this is a team game with
                  heavy focus around character synergy, but to best reflect this
                  relationship, they have been allocated to T0.5.
                </li>
              </ul>
              <li>
                <strong>T1</strong> - Characters that excel in their role or in
                a frequently occurring niche.
              </li>
              <ul>
                <li>
                  These characters excel at what they do, and are regularly
                  called upon to fill that niche. These characters are staples
                  to the roster and see frequent use across GFL2’s various game
                  modes.
                </li>
              </ul>
              <li>
                <strong>T2</strong> - Characters that excel in an uncommon, but
                important niche.
              </li>
              <ul>
                <li>
                  These can be highly specialized or highly situational
                  characters who, owing to some external factor, only see
                  limited play.
                </li>
              </ul>
              <li>
                <strong>T3</strong> - Characters playable if you like them.
              </li>
              <ul>
                <li>
                  These characters struggle to make a noticeable impact. Given
                  the game’s low baseline difficulty, it is entirely possible
                  that these characters perform (in some limited capacity) the
                  niche they were intended to fill.
                </li>
                <li>
                  While players should never be discouraged from playing the
                  characters they like, it should be noted that there will be a
                  significant gap in performance between T3s and their stronger
                  alternatives.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Changelog
            <span className="tag new">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <h6>17.03.2025</h6>
            <p>
              <strong>Global changes:</strong>
            </p>
            <ul>
              <li>
                Added{' '}
                <GFLCharacter
                  slug="klukai"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                to Global tier lists.
              </li>
              <li>
                <GFLCharacter
                  slug="tololo"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T0.5
                <FontAwesomeIcon
                  icon={faArrowDown}
                  width="18"
                  className="arrow-red"
                />{' '}
                T1 in Bossing. V0 performance rating adjusted closer to actual
                one based on the recent Gunsmoke phases.
              </li>
            </ul>
            <p>
              <strong>CN changes:</strong>
            </p>
            <ul>
              <li>
                Added{' '}
                <GFLCharacter
                  slug="nikketa"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                to CN tier lists.
              </li>
              <li>
                Added{' '}
                <GFLCharacter
                  slug="yoohee"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                to CN tier lists.
              </li>
              <li>
                <GFLCharacter
                  slug="qiuhua"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T0
                <FontAwesomeIcon
                  icon={faArrowDown}
                  width="18"
                  className="arrow-red"
                />{' '}
                T1 in Bossing. V0 initial performance rating adjusted closer to
                actual one based on more testing done.
              </li>
              <li>
                <GFLCharacter
                  slug="klukai"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T1
                <FontAwesomeIcon
                  icon={faArrowDown}
                  width="18"
                  className="arrow-red"
                />{' '}
                T2 in Bossing. V0 performance rating adjusted closer to actual
                compared to recently released characters in CN.
              </li>
              <li>
                <GFLCharacter
                  slug="sharkry"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T2
                <FontAwesomeIcon
                  icon={faArrowUp}
                  width="18"
                  className="arrow-green"
                />{' '}
                T0.5 in both Bossing and General. Adjusted for significant Mod
                Key performance boost.
              </li>
              <li>
                <GFLCharacter
                  slug="peri"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T1
                <FontAwesomeIcon
                  icon={faArrowDown}
                  width="18"
                  className="arrow-red"
                />{' '}
                T2 in both Bossing and General. Adjusted down because of Sharkry
                Mod Key release, which has the same effect as Peri with more
                damage. Also, Peri has also been Reclassed to Core Support to
                closer reflect her role as a Qiuhua passive stacker.
              </li>
              <li>
                <GFLCharacter
                  slug="tololo"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                T0
                <FontAwesomeIcon
                  icon={faArrowDown}
                  width="18"
                  className="arrow-red"
                />{' '}
                T2 in General. V0 performance rating adjusted closer to actual
                one.
              </li>
              <li>
                <GFLCharacter
                  slug="daiyan"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                Adjusted for Mod Key performance boost. Reclassed to Core for
                synergy with Yoohee as a damage dealer (ratings stays the same -
                T1).
              </li>
            </ul>
            <h6>03.02.2025</h6>
            <p>
              Added <strong>Centaureissi</strong> to the Global tier list.
            </p>
            <h6>03.02.2025</h6>
            <p>
              Added <strong>Dushevnaya</strong> to the Global tier list.
            </p>
            <h6>28.01.2025</h6>
            <p>
              Tier list has been fully revamped and the tabs above have been
              updated with new information.
            </p>
            <h6>14.01.2025</h6>
            <p>Added Daiyan to the Global tier list.</p>
            <h6>25.12.2024</h6>
            <p>
              After receiving feedback about the tier list, we've decided to
              change Vepley's category and adjust her rating.
            </p>
            <ul>
              <li>
                <GFLCharacter
                  slug="vepley"
                  mode="inline"
                  enablePopover
                  showLabel
                />{' '}
                has been moved from DPS to Amplifier category. You don't really
                bring her to the battle for her damage (which is just okay), but
                for the damage taken debuff (which makes her decent on bosses)
                and other utility she provides. Her rating on Global has also
                been adjusted.
              </li>
            </ul>
            <p>
              We also received additional feedback and are considering splitting
              the tier list from a generic one to two separate categories:
              Generic content and Bossing. Since different characters shine in
              different places, having a single rating that tries to average the
              performance isn't fair.
            </p>
            <h6>22.12.2024</h6>
            <p>
              We have updated both the Global tier list to account for upcoming
              characters announced to be coming to the game in a few days and
              also updated the CN tier list to include Springfield and Faye +
              their impact on the meta.
            </p>
            <ul>
              <li>Global tier list:</li>
              <ul>
                <li>
                  <GFLCharacter
                    slug="makiatto"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  added to the tier list with S rating,
                </li>
                <li>
                  <GFLCharacter
                    slug="papasha"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  added to the tier list with B rating,
                </li>
                <li>
                  <GFLCharacter
                    slug="ksenia"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  has been moved from Sustain to Amplifier category. Her buffs
                  is the reason you bring her to the battle and her healing is
                  secondary, so we decided to move her,
                </li>
                <li>
                  <GFLCharacter
                    slug="sharkry"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  B{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  A. Even at V0, Sharkry proved to be way stronger in the Global
                  server that we anticipated and is commonly used in various
                  content, so we're raising her up a tier to distance her from
                  the other characters available in the B tier,
                </li>
                <li>
                  <GFLCharacter
                    slug="mosin-nagant"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  A{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  B. With the release of Makiatto we're getting a much stronger
                  Sniper who moving forward will be the primary choice for the
                  role in the team. And the gap between their performance is big
                  enough to warrant the drop of Mosing-Nagant down a tier.
                </li>
              </ul>
              <li>CN tier list:</li>
              <ul>
                <li>
                  <GFLCharacter
                    slug="springfield"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  added to the tier list with S+ rating. Springfield is Hydro
                  support who excels both at buffing and healing her allies, but
                  also doing quite a lot of damage herself. She's very like
                  Suomi, but instead of Shielding, she focuses on overhealing
                  and leans more into buffing/debuffing which is why she was
                  placed in the Amplifier category,
                </li>
                <li>
                  <GFLCharacter
                    slug="faye"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  added to the tier list with A rating. She's a DPS/Debuffer
                  hybrid who can increase the Physical Damage taken by enemies.
                  While this is quite a strong debuff, Physical element isn't in
                  a good spot currently, but maybe it will change in the future,
                </li>
                <li>
                  <GFLCharacter
                    slug="tololo"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  B{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S. Tololo is a totally different character now with both her
                  Mod Key and Springfield release. She finally has a Hydro
                  support that can raise her potential to a whole new level,
                  becoming a menace that can compete with the best DPS in the
                  game - hell, she is one of the best DPS in the game now
                  already at V0, but if you obtain more of her dupes, she
                  becomes EVEN better (considering she's standard character),
                </li>
                <li>
                  <GFLCharacter
                    slug="zhaohui"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  C{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  B. Another Hydro character that benefited from the release of
                  Springfield. The additional buffs help her with dealing damage
                  and keep her save on the frontline,
                </li>
                <li>
                  <GFLCharacter
                    slug="ksenia"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  A{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S. Ksenia is the best SR character in the game who can be
                  slotted in quite a lot of teams when you need a buffer (with
                  some side-healing on top), so we're raising her to better
                  reflect that. She also has been moved from Sustain to
                  Amplifier category. Her buffs is the reason you bring her to
                  the battle and her healing is secondary,
                </li>
                <li>
                  <GFLCharacter
                    slug="dushevnaya"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  A{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  B. Dush is in a pretty weird spot when you look at her
                  performance at V0. While she is great pick the Freeze team,
                  the buffs she brings are actually worse than some of the
                  generic buffers can provide and she only becomes better with
                  dupes. Since the tier list rates characters at their base kit,
                  we have decided to drop Dush down a tier - as at V0 she isn't
                  really a must have for the Freeze it and is more of a premium
                  pick that you need to heavily invest into,
                </li>
                <li>
                  <GFLCharacter
                    slug="centaureissi"
                    mode="inline"
                    enablePopover
                    showLabel
                  />{' '}
                  S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A. Similar to Dush, centaureissi's performance at V0 is not
                  good enough to keep her in the Top Picks category as she's
                  basically a heal bot only then. That's why we have dropped her
                  down a tier.
                </li>
              </ul>
            </ul>
            <h6>02.12.2024</h6>
            <p>Official release of the Global and CN tier lists.</p>
            <h6>28.11.2024</h6>
            <p>
              Initial release of the tier list page. Ratings are still in
              progress.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'global' && 'General - Global'}
            {sortOption === 'global_boss' && 'Bossing - Global'}
            {sortOption === 'cn' && 'General - CN'}
            {sortOption === 'cn_boss' && 'Bossing - CN'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'global' && 'General (Global)'}
            {sortOption === 'global_boss' && 'Bossing (Global)'}
            {sortOption === 'cn' && 'General (CN)'}
            {sortOption === 'cn_boss' && 'Bossing (CN)'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'global' &&
            'how the character performs in non-bossing content available in the Global server (list in the tabs above).'}
          {sortOption === 'global_boss' &&
            'how the character performs in bossing content available in the Global server (list in the tabs above).'}
          {sortOption === 'cn_boss' &&
            'how the character performs in bossing content available in the CN server (list in the tabs above).'}
          {sortOption === 'cn' &&
            'how the character performs in non-bossing content available in the CN server (list in the tabs above).'}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option global ${sortOption === 'global' && 'selected'}`}
          onClick={() => setSortOption('global')}
        >
          <p>General (Global)</p>
        </div>
        <div
          className={`option global_boss ${
            sortOption === 'global_boss' && 'selected'
          }`}
          onClick={() => setSortOption('global_boss')}
        >
          <p>Bossing (Global)</p>
        </div>
        <div
          className={`option cn ${sortOption === 'cn' && 'selected'}`}
          onClick={() => setSortOption('cn')}
        >
          <p>General (CN)</p>
        </div>
        <div
          className={`option cn_boss ${sortOption === 'cn_boss' && 'selected'}`}
          onClick={() => setSortOption('cn_boss')}
        >
          <p>Bossing (CN)</p>
        </div>
      </div>
      <div className="employees-filter-bar gfl">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-gfl">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Core</div>
            <div className="custom-header amplifier">Core Support</div>
            <div className="custom-header healer">Utility</div>
          </div>
        </div>
        <>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Core</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Core Support</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Utility</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Utility')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Core</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Core Support</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Utility</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Utility')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Core</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Core Support</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Utility</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Utility')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Core</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Core Support</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Utility</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Utility')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Core</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Core Support</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Core Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Utility</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'Utility')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ExiliumTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List (GFL 2) | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Prydwen's tier list for Girls' Frontline 2: Exilium (GFL 2) that rates all currently available characters."
    game="exilium"
  />
);
